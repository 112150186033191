<template>
    <div class="leaveBox_box_css">
        <div class="leaveBox_img_css">
            <div>12</div>
            <div> 当日请假 </div>
        </div>
        <div class="leaveBox_img_css">
            <div>203</div>
            <div> 本周请假 </div>
        </div>
        <div class="leaveBox_img_css">
            <div>865</div>
            <div> 本月请假 </div>
        </div>
        <div class="leaveBox_img_css">
            <div>2543</div>
            <div> 本学期请假 </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.leaveBox_box_css{
    display: flex;
    align-items: center;
    color:#fff;
}
.leaveBox_img_css{
    width: 180px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 20px;
    background-size:100% 100%;
    background-image: url("../../assets/principalScreen/5e97e2b884a3e1587012280205.png");
}
.leaveBox_img_css>div:nth-child(1){
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #d9dafffd;
}
.leaveBox_img_css>div:nth-child(2){
    font-size: 14px;
    color: #9e8dffc5;
}
</style>