<template>
  <div>
    <div class="screen_box_css">
        <div
        class="screen_box_item_css"
        v-for="(item, index) in showList"
        :key="index"
        :class="{ select_box_css: showValue == index }"
        @click="selectionRange(index, item)"
      >
        {{ item }}
      </div>
      <el-date-picker
        v-model="timeValue"
        type="daterange"
        align="right"
        unlink-panels
        class="pickerTime"
        ref="datePicker"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <div class="leaveNumber">
      <div v-for="(item, index) in dataList" :key="index">
        <div class="leaveNumber_name_css">
          {{ item.name }}
        </div>
        <div
          class="gradation_css"
          :style="{ width: item.number * 12 + 'px' }"
        ></div>
        <div class="leaveNumber_number_css">{{ item.number }}人</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        { name: "2023级1班", number: 20 },
        { name: "2023级1班", number: 17 },
        { name: "2023级1班", number: 18 },
        { name: "2023级1班", number: 5 },
        { name: "2023级1班", number: 2 },
        { name: "2023级1班", number: 3 },
        { name: "2023级1班", number: 6 },
        { name: "2023级1班", number: 6 },
        { name: "2023级1班", number: 18 },
        { name: "2023级1班", number: 12 },
        { name: "2023级1班", number: 10 },
        { name: "2023级1班", number: 4 },
        { name: "2023级1班", number: 4 },
        { name: "2023级1班", number: 10 },
        { name: "2023级1班", number: 21 },
        { name: "2023级1班", number: 24 },
        { name: "2023级1班", number: 1 },
      ],
      showValue: 2,
      showList: ["本学期", "本月", "本周", "今天", "自定义"],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeValue: "",
    };
  },
  created() {
    function order(a, b) {
      if (a.number <b.number) {
        return 1;
      } else if (a.number > b.number) {
        return -1;
      } else if (a.number == b.number) {
        return 0;
      }
    }
    this.dataList.sort(order);
  },
  methods: {
    selectionRange(index, item) {
      this.showValue = index;
      if (item == "自定义") {
        this.$refs.datePicker.focus();
      }
    },
    }
};
</script>
<style scoped>
.gradation_css {
  position: relative;
  width: 400px;
  height: 18px;
  background-image: linear-gradient(to right, #f957ff, #136bcf);
}
.gradation_css::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background: repeating-linear-gradient(
    90deg,
    #05042c,
    #05042c 3px,
    transparent 5px,
    transparent 10px
  );
}
.leaveNumber {
  padding: 0 15px 0 25px;
}
.leaveNumber > div {
  padding: 11.5px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.leaveNumber_name_css {
  width: 82px;
  flex-shrink: 0;
  color: #9e9bfa;
}
.leaveNumber_number_css {
  margin-left: 20px;
  flex-shrink: 0;
  font-weight: bold;
}
.leaveNumber > div:nth-child(-n + 3) > .gradation_css {
  background-image: linear-gradient(to right, #ff57b3, #ff3232) !important;
}
.leaveNumber > div:nth-child(-n + 3) > .leaveNumber_number_css {
  color: #ff0d0d;
}
.leaveNumber > div:nth-child(n + 4) > .gradation_css {
  background-image: linear-gradient(to right, #79f3b6, #ff8e0d) !important;
}
.leaveNumber > div:nth-child(n + 4) > .leaveNumber_number_css {
  color: #ff8e0d;
}
.leaveNumber > div:nth-child(n + 7) > .gradation_css {
  background-image: linear-gradient(to right, #f8bbfa, #458bdb) !important;
}
.leaveNumber > div:nth-child(n + 7) > .leaveNumber_number_css {
  color: #458bdb;
}
</style>