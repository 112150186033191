<template>
  <!-- <div class="border-top-css"></div>
        <div class="border-bottom-css"></div>
        <div class="border-left-css"></div>
        <div class="border-right-css"></div> -->
  <div class="ps_pages_Css">
    <img class="ps_top_bg_css" src="@/assets/principalScreen/9529csccccc.png" alt="" />
    <!-- <div @click="showT = !showT" class="ps_left_css"></div>
    <div @click="showT = !showT" class="ps_right_css"></div> -->
    <div>
      <!-- 中间大图 -->
      <masterMap />

      <!-- 全校班级排名 -->
      <classRanking class="ps_box_css ps_classRanking" />

      <!-- 全校德智体美劳 -->
      <evaluate class="ps_box_css ps_evaluate" />

      <!-- 教师、家长点赞 -->
      <teacher class="ps_box_css ps_teacher" />

      <!-- 学生获赞 -->
      <parent class="ps_box_css ps_patriarch" />
    </div>
    <!-- <transition name="fade" mode="out-in">
      <div v-if="showT" key="fadeA">
        
        <masterMap />

        <classRanking class="ps_box_css ps_classRanking" />

        <evaluate class="ps_box_css ps_evaluate" />

        <teacher class="ps_box_css ps_teacher" />

        <parent class="ps_box_css ps_patriarch" />
      </div>
      <div v-else key="fadeB">
        <leaveBox class="ps_leaveBox" />
        <div class="ps_box_css ps_dailyAttendance">
          <div class="ps_box-top-css">
            <div class="ps_box-top-css-div">
              <el-dropdown @command="command">
                <span style="margin-right: 15px;color:#e7e7e7d5;" class="span_css">
                  {{ yearsValue }}级
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in years"
                    :key="index"
                    :command="item"
                  >{{ item }}级</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span @click="selectionRange">{{ getTime(dayValue) }}考勤记录</span>
              <el-date-picker
                v-model="dayValue"
                align="right"
                type="date"
                class="pickerTimeA"
                ref="datePickerA"
                placeholder="选择日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <div class="ps_box-top-css-after"></div>
            <div class="ps_box-top-css-before"></div>
          </div>
          <div class="ps_box-content-css ps_dailyAttendance_content">
            <dailyAttendance />
            <div class="ps_content-border-css">
              <div></div>
            </div>
          </div>
        </div>
        <div class="ps_box_css ps_leaveStatistics">
          <div class="ps_box-top-css">
            <div class="ps_box-top-css-div">全校请假人数统计</div>
            <div class="ps_box-top-css-after"></div>
            <div class="ps_box-top-css-before"></div>
          </div>
          <div class="ps_box-content-css ps_leaveStatistics_content">
            <leaveStatistics />
            <div class="ps_content-border-css">
              <div></div>
            </div>
          </div>
        </div>
        <div class="ps_box_css ps_leaveNumber">
          <div class="ps_box-top-css">
            <div class="ps_box-top-css-div">班级请假人数</div>
            <div class="ps_box-top-css-after"></div>
            <div class="ps_box-top-css-before"></div>
          </div>
          <div class="ps_box-content-css ps_height340">
            <leaveNumber />
            <div class="ps_content-border-css">
              <div></div>
            </div>
          </div>
        </div>
        <div class="ps_box_css ps_leaveCompare">
          <div class="ps_box-top-css">
            <div class="ps_box-top-css-div">各年级请假数量</div>
            <div class="ps_box-top-css-after"></div>
            <div class="ps_box-top-css-before"></div>
          </div>
          <div class="ps_box-content-css ps_leaveCompare_content">
            <leaveCompare />
            <div class="ps_content-border-css">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </transition> -->
  </div>
</template>
<script>
import moment from "moment";
import masterMap from "./masterMap.vue";
import classRanking from "./classRanking.vue";
import dailyAttendance from "./dailyAttendance.vue";
import evaluate from "./evaluate.vue";
import teacher from "./teacher.vue";
import leaveStatistics from "./leaveStatistics.vue";
import leaveNumber from "./leaveNumber.vue";
import leaveCompare from "./leaveCompare.vue";
import leaveBox from "./leaveBox.vue";
import parent from "./parent.vue";
// import {
//   GradeReportGrowthTrends
// } from "@/api/index.js";
export default {
  components: {
    classRanking,
    dailyAttendance,
    evaluate,
    teacher,
    masterMap,
    leaveStatistics,
    leaveNumber,
    leaveCompare,
    leaveBox,
    parent,
  },
  data() {
    return {
      showT: true,
      years: [],
      yearsValue: 2023,
      dayValue: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  created() {
    let ya = moment().format("YYYY");
    let mm = moment().format("MM");
    this.dayValue = moment().format("YYYY-MM-DD");
    this.years = [];
    if (mm < 9) {
      for (let i = 5; i >= 0; i--) {
        this.years.push(Number(ya) - i - 1);
      }
    } else {
      for (let i = 5; i >= 0; i--) {
        this.years.push(Number(ya) - i);
      }
    }
    this.yearsValue = this.years[this.years.length - 1];
  },
  methods: {
    getTime(time) {
      return moment(time).format("MM月DD日");
    },
    selectionRange() {
      this.$refs.datePickerA.focus();
    },
    command(e) {
      this.yearsValue = e;
    },
  },
};
</script>
<style>
.el-picker-panel,
.el-dropdown-menu {
  background-color: #082f6ed2;
  border: 1px solid #053581d2;
  color: #ffffffd5;
}
.el-picker-panel__sidebar {
  background-color: transparent;
  border-right: 1px solid #3862a7;
  color: #ffffffd5;
}
.el-picker-panel__icon-btn,
.el-dropdown-menu__item {
  color: #ffffffd5;
}
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover {
  background-color: #315ea5d2;
}
.el-popper[x-placement^="top"] .popper__arrow {
  border-top-color: #082f6ed2;
}
.el-popper[x-placement^="top"] .popper__arrow::after {
  border-top-color: #082f6ed2;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #082f6ed2;
}
.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #082f6ed2;
}
.el-date-range-picker__content.is-left {
  border-right: 1px solid #3862a7;
}
.el-date-table th,
.el-picker-panel__shortcut {
  color: inherit;
}
.el-date-table th {
  border-bottom: 1px solid #3862a7;
}
.screen_box_css {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 15px 5px 0;
  position: relative;
}
.screen_box_item_css {
  margin-left: 10px;
  border-radius: 4px;
  background-color: #082a60d3;
  color: #d1d1d1;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  cursor: pointer;
}
.select_box_css {
  background-color: #1250b3 !important;
  color: #fff !important;
}
.pickerTime {
  opacity: 0;
  position: absolute;
  right: 64px;
  top: 15px;
  width: 0 !important;
  height: 0;
}
.pickerTimeA {
  opacity: 0;
  position: absolute;
  right: 50%;
  top: 15px;
  width: 0 !important;
  height: 0 !important;
}
.pickerTimeA > input {
  height: 0 !important;
  padding: 0 !important;
}
.el-date-table td.disabled div {
  background-color: #232444;
}
.el-date-picker__header-label {
  color: #fff;
}
</style>
<style>
.ps_pages_Css {
  /* background-image: url("../../assets/principalScreen/5e743a83226171584675459667.jpg");
  background-size: cover; */
  background-color: #000127;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.ps_top_bg_css {
  position: fixed;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  top: 0;
  width: 120%;
}
.ps_left_css {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
}
.ps_right_css {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
}
.ps_box_css {
  position: absolute;
  width: 470px;
  color: #e7e7e7d5;
  overflow: hidden;
}
.ps_dailyAttendance {
  width: 1335px !important;
  right: 40px;
  top: 560px;
}
.ps_dailyAttendance > .ps_box-top-css {
  background-image: linear-gradient(to right, transparent, #68590118, #68590156, #685801b0, #68590156, #68590118, transparent) !important;
}

.ps_classRanking {
  left: 40px;
  top: 510px;
}
.ps_evaluate {
  left: 40px;
  top: 100px;
}
.ps_student {
  right: 40px;
  top: 100px;
}
.ps_teacher {
  right: 40px;
  top: 100px;
}
.ps_patriarch {
  right: 40px;
  top: 410px;
}
.ps_leaveStatistics {
  width: 828px !important;
  left: 546px;
  top: 210px;
}
.ps_leaveNumber {
  left: 40px;
  top: 100px;
}
.ps_leaveNumberA {
  left: 40px;
  top: 512px;
}
.ps_leaveCompare {
  right: 40px;
  top: 100px;
}
.ps_leaveBox {
  position: absolute;
  top: 105px;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
}
.ps_box-top-css {
  position: relative;
  width: 100%;
  height: 44px !important;
  padding: 6px 0 6px 0;
  box-sizing: border-box;
  background-image: linear-gradient(to right, transparent, #020d2562, #020d25b0, #02132eb0, #020d25b0, #020d2562, transparent);
}
.ps_box-top-css-div {
  text-align: center;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;
  background-image: linear-gradient(to right, transparent, #000e25, #041d46, #082863, #041d46, #000e25, transparent);
}
.ps_box-top-css-div > span,
.ps_span_css {
  cursor: pointer;
  color: #e7e7e7d5;
  font-size: 16px;
}
.ps_box-top-css-div > span:hover,
.ps_span_css:hover {
  color: #a1a4ff;
}
.ps_box-top-css-after {
  position: absolute;
  left: 0;
  top: 50%;
  background-image: url("../../assets/principalScreen/9529c07e20fafa0c12af103aa56144f.png");
  width: 32px;
  height: 38px;
  background-size: 32px 38px;
  transform: translateY(-50%);
  animation: twinkling 2.1s linear infinite;
}
.ps_box-top-css-before {
  position: absolute;
  right: 0;
  top: 50%;
  background-image: url("../../assets/principalScreen/9529c07e20fafa0c12af103aa56144f.png");
  width: 32px;
  height: 38px;
  background-size: 32px 38px;
  transform: translateY(-50%) rotate(180deg);
  animation: twinkling 2.1s linear infinite;
}
.ps_dailyAttendance > .ps_box-top-css > .ps_box-top-css-div {
  background-image: linear-gradient(to right, transparent, #eb99000e, #eb990069, #eb98009c, #eb990069, #eb99000e, transparent) !important;
}
@keyframes twinkling {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.ps_box-content-css {
  background-color: #1f11412f;
  /* background-color: red; */
  height: 200px;
  position: relative;
  overflow: hidden !important;
  clip-path: polygon(0 0, 100% 0, 100% 180px, 450px 100%, 20px 100%, 0 180px);
}
.ps_height350 {
  height: 350px !important;
  clip-path: polygon(0 0, 100% 0, 100% 330px, 450px 100%, 20px 100%, 0 330px) !important;
}
.ps_height340 {
  height: 760px !important;
  clip-path: polygon(0 0, 100% 0, 100% 740px, 450px 100%, 20px 100%, 0 740px) !important;
}
.ps_height250 {
  height: 250px !important;
  clip-path: polygon(0 0, 100% 0, 100% 230px, 450px 100%, 20px 100%, 0 230px) !important;
}
.ps_height452 {
  height: 452px !important;
  clip-path: polygon(0 0, 100% 0, 100% 432px, 450px 100%, 20px 100%, 0 432px) !important;
}
.ps_dailyAttendance_content {
  height: 300px !important;
  clip-path: polygon(0 0, 100% 0, 100% 280px, 1315px 100%, 20px 100%, 0 280px) !important;
}
.ps_leaveStatistics_content {
  height: 280px !important;
  clip-path: polygon(0 0, 100% 0, 100% 260px, 808px 100%, 20px 100%, 0 260px) !important;
}
.ps_leaveCompare_content {
  height: 390px !important;
  clip-path: polygon(0 0, 100% 0, 100% 370px, 450px 100%, 20px 100%, 0 370px) !important;
}
.ps_content-border-css {
  --box-show-color: #4459b8;
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 2px;
  background-color: var(--box-show-color);
}
.ps_content-border-css::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  height: 2px;
  width: 100px;
  transform: translateX(-40px) rotate(45deg);
  background-color: var(--box-show-color);
}
.ps_content-border-css::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 10px;
  height: 2px;
  width: 100px;
  transform: translateX(40px) rotate(-45deg);
  background-color: var(--box-show-color);
}
.ps_content-border-css > div {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
.ps_content-border-css > div::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 2px;
  height: 100px;
  background-image: linear-gradient(0deg, var(--box-show-color), transparent);
}
.ps_content-border-css > div::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2px;
  height: 100px;
  background-image: linear-gradient(0deg, var(--box-show-color), transparent);
}

.NoRankingYet {
  text-align: center;
  line-height: 280px;
}
</style>
