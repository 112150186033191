<template>
  <div>
    <img class="c442578521668848trhf" src="@/assets/principalScreen/442578521668848trhf.png" alt="" />
    <img class="content_bg_css" src="@/assets/principalScreen/cscsktjhijdnjas56.png" alt="" />
    <img class="box_content_bg_css" src="@/assets/principalScreen/cscfppkkkm3.png" alt="" />
    <img class="box_box_content_bg_css" src="@/assets/principalScreen/thjrjjjdsjkdjjd54.png" alt="" />
    <img class="xinzhang_bg_css" src="@/assets/principalScreen/6494f68ac9fcc1687484042958.png" alt="" />
    <img class="onbdhif65214788633255" src="@/assets/principalScreen/onbdhif65214788633255.png" alt="" />
    <div class="block_box_css">
      <div v-for="(item, index) in listNumber" :key="index">
        <div>{{ item.number }}</div>
        <div>{{ item.title }}</div>
        <img src="@/assets/principalScreen/vbnhnhdrrejkgkhjs451.png" alt="" />
      </div>
    </div>

    <div class="wu_box_css">
      <div class="de_pp_css">
        <img class="ppx_img_css" src="@/assets/principalScreen/de_pp.png" alt="" />
        <div class="number_css">{{ valArr[0] }}</div>
        <div class="zanbi_css">占比：{{ getValue(0) }}</div>
      </div>
      <div class="zhi_pp_css">
        <img class="ppx_img_css" src="@/assets/principalScreen/zhi_pp.png" alt="" />
        <div class="number_css">{{ valArr[1] }}</div>
        <div class="zanbi_css">占比：{{ getValue(1) }}</div>
      </div>
      <div class="ti_pp_css">
        <img class="ppx_img_css" src="@/assets/principalScreen/ti_pp.png" alt="" />
        <div class="number_css">{{ valArr[2] }}</div>
        <div class="zanbi_css">占比：{{ getValue(2) }}</div>
      </div>
      <div class="mei_pp_css">
        <img class="ppx_img_css" src="@/assets/principalScreen/mei_pp.png" alt="" />
        <div class="number_css">{{ valArr[3] }}</div>
        <div class="zanbi_css">占比：{{ getValue(3) }}</div>
      </div>
      <div class="lao_pp_css">
        <img class="ppx_img_css" src="@/assets/principalScreen/lao_pp.png" alt="" />
        <div class="number_css">{{ valArr[4] }}</div>
        <div class="zanbi_css">占比：{{ getValue(4) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { SchoolReportGrowthTrends2, SchoolUserData } from "@/api/index.js";
export default {
  data() {
    return {
      valArr: [0, 0, 0, 0, 0],
      listNumber: [
        {
          title: "总人数",
          number: 4250,
        },
        {
          title: "管理人员",
          number: 84,
        },
        {
          title: "教师数量",
          number: 860,
        },
        {
          title: "学生数量",
          number: 3650,
        },
      ],
    };
  },
  created() {
    SchoolReportGrowthTrends2({
      startDate: "",
      endDate: "",
    }).then((res) => {
      let listArr = [
        { ttID: "1", Score: 0 },
        { ttID: "2", Score: 0 },
        { ttID: "3", Score: 0 },
        { ttID: "4", Score: 0 },
        { ttID: "5", Score: 0 },
      ];
      for (let i = 0; i < listArr.length; i++) {
        for (let j = 0; j < res.data.length; j++) {
          if (listArr[i].ttID == res.data[j].ttID) {
            listArr[i].Score = Number(res.data[j].Score);
            break;
          }
        }
      }
      this.valArr = listArr.map(function(obj) {
        return obj.Score;
      });
    });
    SchoolUserData().then(res =>{
        this.listNumber[0].number = res.data.alluser
        this.listNumber[1].number = res.data.manager
        this.listNumber[2].number = res.data.teacher
        this.listNumber[3].number = res.data.student
    })
  },
  methods: {
    getValue(e) {
      let an = 0;
      this.valArr.map((val) => {
        an += val;
      });
      return ((this.valArr[e] / an) * 100).toFixed(1) + "%";
    },
  },
};
</script>

<style scoped>
.c442578521668848trhf {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  top: 95px;
  height: 26px;
}
.content_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 270px;
  width: 1450px;
}
.box_content_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 130px;
  width: 780px;
}

.box_box_content_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 610px;
  width: 690px;
}
.xinzhang_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 110px;
  width: 760px;
}
.wu_box_css {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 110px;
  width: 760px;
  height: 480px;
}
.de_pp_css {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 120px;
  z-index: 15;
  text-align: center;
  padding-top: 122px;
}
.zhi_pp_css {
  position: absolute;
  top: 332px;
  left: 40px;
  width: 120px;
  z-index: 15;
  text-align: center;
  padding-top: 122px;
}
.ti_pp_css {
  position: absolute;
  top: -2px;
  left: 549px;
  width: 120px;
  z-index: 15;
  text-align: center;
  padding-top: 122px;
}
.mei_pp_css {
  position: absolute;
  top: 174px;
  left: 638px;
  width: 120px;
  z-index: 15;
  text-align: center;
  padding-top: 122px;
}
.lao_pp_css {
  position: absolute;
  top: 350px;
  left: 566px;
  width: 120px;
  z-index: 15;
  text-align: center;
  padding-top: 122px;
}
.number_css {
  color: #11dbff;
  font-size: 17px;
}
.zanbi_css {
  color: #d1d1db;
  font-size: 14px;
  margin-top: 2px;
}

.ppx_img_css {
  width: 120px;
  position: absolute;
  top: 0;
  left: 0;
  animation: floatAn 2s infinite;
  transform: translateZ(0);
}
@keyframes floatAn {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.onbdhif65214788633255 {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 140px;
  width: 160px;
}
.block_box_css {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 832px;
  display: flex;
  align-items: center;
}
.block_box_css > div {
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 160px;
  height: 65px;
}
.block_box_css > div:nth-child(1) {
  color: #08c7f1;
}
.block_box_css > div:nth-child(2) {
  color: #ffd725;
}
.block_box_css > div:nth-child(3) {
  color: #ad55ff;
}
.block_box_css > div:nth-child(4) {
  color: #0dde79;
}
.block_box_css > div > div:nth-child(1) {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 1px;
}
.block_box_css > div > div:nth-child(2) {
  font-size: 15px;
}
.block_box_css > div > img {
  width: 160px;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
