<template>
  <div>
    <div class="flex_css fixed_Css">
      <div>班级</div>
      <div>应到</div>
      <div>早读</div>
      <div>第一节课</div>
      <div>第二节课</div>
      <div>第三节课</div>
      <div>第四节课</div>
      <div>午休</div>
      <div>第五节课</div>
      <div>第六节课</div>
      <div>托管</div>
    </div>
    <div class="dailyAttendance_box_css">
      <div class="flex_css">
        <div>班级</div>
        <div>应到</div>
        <div>早读</div>
        <div>第一节课</div>
        <div>第二节课</div>
        <div>第三节课</div>
        <div>第四节课</div>
        <div>午休</div>
        <div>第五节课</div>
        <div>第六节课</div>
        <div>托管</div>
      </div>
      <div class="flex_css" v-for="(item, index) in tableData" :key="index">
        <div>{{ item.className }}</div>
        <div>{{ item.yingdao }}</div>
        <div v-for="(it, idx) in item.lesson" :key="idx" style="color: #adadad">
          <div class="WKJ_css" v-if="!it.SD && !it.WD && !it.QJ">未课检</div>
          <div v-else>
            <el-tooltip placement="top">
              <div class="yinChang_css">
                <span class="WD_css" v-if="it.WD > 0"> 未到{{ it.WD }}人 </span>
                <span class="QJ_css" v-if="it.QJ > 0"> 请假{{ it.QJ }}人 </span>
                <span class="SD_css" v-if="it.SD > 0"> 实到{{ it.SD }}人 </span>
              </div>
              <div slot="content">
                <span class="WD_css" v-if="it.WD > 0"> 未到{{ it.WD }}人 </span>
                <span class="QJ_css" v-if="it.QJ > 0"> 请假{{ it.QJ }}人 </span>
                <span class="SD_css" v-if="it.SD > 0"> 实到{{ it.SD }}人 </span>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          className: "2023级1班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级2班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级3班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级4班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级5班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级6班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级7班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级8班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级9班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级10班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级11班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
        {
          className: "2023级12班",
          yingdao: "45",
          lesson: [
            { lessonName: "早读", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第一节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第二节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第三节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第四节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "午休", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第五节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "第六节课", WD: 15, SD: 25, QJ: 12 },
            { lessonName: "托管", WD: 15, SD: 25, QJ: 12 },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.dailyAttendance_box_css {
  height: 300px;
  width: 1335px;
  overflow: auto;
}
.flex_css {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-align: center;
  color: #d3d3d3;
  padding: 8px 0;
}
.fixed_Css {
  position: absolute;
  background-color: #05042c;
}
.flex_css:nth-child(2n) {
  background-color: #18083d88;
}
.flex_css > div {
  width: 130px;
  flex-shrink: 0;
  padding: 0 2px;
}
.yinChang_css {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.flex_css > div:nth-child(1) {
  width: 110px !important;
}
.flex_css > div:nth-child(2) {
  width: 50px !important;
}
.SD_css {
  color: #2f922f;
  margin: 0 6rpx;
}
.WD_css {
  color: #f08f91;
  margin: 0 6rpx;
}
.QJ_css {
  color: #eb732d;
  margin: 0 6rpx;
}
.WKJ_css {
  color: #da1421;
  margin: 0 6rpx;
}
</style>
