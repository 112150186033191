<template>
  <div>
    <div class="screen_box_css about_css">
      <div
        class="screen_box_item_css"
        v-for="(item, index) in showList"
        :key="index"
        :class="{ select_box_css: showValue == index }"
        @click="selectionRange(index, item)"
      >
        {{ item }}
      </div>
      <el-date-picker
        v-model="timeValue"
        type="daterange"
        align="right"
        unlink-panels
        class="pickerTime"
        ref="datePicker"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <div id="leaveCompare" class="leaveCompare-leaveCompare-css"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
let myChart = null;
export default {
  data() {
    return {
      showValue: 2,
      showList: ["本学期", "本月", "本周", "今天", "自定义"],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeValue: "",
    };
  },
  created() {
    this.leaveCompare();
  },
  methods: {
    selectionRange(index, item) {
      this.showValue = index;
      if (item == "自定义") {
        this.$refs.datePicker.focus();
      }
    },
    async leaveCompare() {
      if (myChart) {
        myChart.dispose();
      }

      myChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      myChart = echarts.init(document.getElementById("leaveCompare"));

      var allnum = 0;
      var arr = [
        {
          name: "2023级",
          value: 2500,
        },
        {
          name: "2022级",
          value: 1500,
        },
        {
          name: "2021级",
          value: 1500,
        },
        {
          name: "2020级",
          value: 1000,
        },
        {
          name: "2019级",
          value: 1500,
        },
        {
          name: "2018级",
          value: 0,
        },
      ];
      arr.forEach((val) => {
        allnum += parseInt(val.value);
      });
      // 绘制图表
      myChart.setOption({
        animation: true,
        title: {
          text: allnum,
          subtext: "总数",
          x: "center",
          y: "center",
          textStyle: {
            color: "#fff",
            fontSize: 27,
            fontWeight: "600",
            align: "center",
            // "width": "200px"
          },
          subtextStyle: {
            color: "#fff",
            fontSize: 18,
            fontWeight: "normal",
            align: "center",
          },
        },
        tooltip: {
          trigger: "item",
          padding: 10,
        },
        legend: {
          itemWidth: 15,
          itemHeight: 15,
          left: "center",
          bottom: 10,
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
          data: arr,
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["35%", "58%"],
            itemStyle: {
              normal: {
                shadowBlur: 10,
                shadowColor: "#aec3cb",
              },
            },
            color: [
              "#267ef2",
              "#ffde00",
              "#00ffff",
              "#ff924f",
              "#00FFA8",
              "#9F17FF",
              "#FFE400",
              "#FE2C8A",
            ],
            startAngle: 135,
            labelLine: {
              normal: {
                length: 25,
              },
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                formatter: "{b}\n{d}%",
              },
            },
            data: arr,
          },
        ],
      });
    },
  },
};
</script>
<style scoped>
.about_css {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}
.leaveCompare-leaveCompare-css{
  width: 470px;
  height: 390px;
}
</style>