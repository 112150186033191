<template>
  <div>
    <div id="leaveStatistics" class="leaveStatistics-leaveStatistics-css"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
let myChart = null;
export default {
  data() {
    return {};
  },
  created() {
    this.leaveStatistics();
  },
  methods: {
    async leaveStatistics() {
      if (myChart) {
        myChart.dispose();
      }

      myChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      myChart = echarts.init(document.getElementById("leaveStatistics"));

      let rq = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
      let eData = [100, 130, 140, 120, 135, 0, 0, 135, 130, 130, 125, 125, 130, 125, 125];
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#ddd",
            },
          },
          backgroundColor: "rgba(255,255,255,1)",
          padding: [5, 10],
          textStyle: {
            color: "#000",
          },
        },

        grid: {
          left: 50,
          right: 30,
          bottom: 30,
          top: 20,
        },
        xAxis: {
          type: "category",
          data: rq,
          boundaryGap: false,
          splitLine: {
            show: true,
            interval: "auto",
            lineStyle: {
              type: "dashed",
              color: ["#082863"],
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#082863",
            },
          },
          axisLabel: {
            // margin: 10,
            textStyle: {
              fontSize: 12,
              color: "#d4d4d4",
            },
          },
        },
        yAxis: [
          {
            name: "",
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["#082863"],
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                fontSize: 12,
                color: "#082863",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "#d4d4d4",
              },
            },
          },
        ],
        series: [
          {
            name: "请假人数",
            type: "line",
            symbolSize: 6,
            data: eData,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: `rgba(0, 62, 246,.2)`,
                    },
                    {
                      offset: 1,
                      color: "rgba(255, 255, 255,0)",
                    },
                  ],
                  false
                ),
              },
            },
            itemStyle: {
              normal: {
                color: `rgb(0, 62, 246)`,
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                shadowColor: `rgba(0, 62, 246, .2)`,
                shadowBlur: 4,
                shadowOffsetY: 25,
              },
            },
          },
        ],
      });
    },
  },
};
</script>
<style scoped>
.leaveStatistics-leaveStatistics-css {
  width: 828px;
  height: 280px;
}
</style>
